.header {
  @apply shadow-sm h-14 flex justify-between items-center gap-4 w-full px-4;
} 

.title {
  @apply text-gray-800 font-semibold truncate;
}

.right {
  @apply flex gap-4;
}